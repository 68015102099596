import { LEAGUES } from '@/constants/leagues';

export const getCompetitionImage = (leagueId, width) => {
    switch (leagueId) {
        case LEAGUES.LA_LIGA:
            return `${process.env.NEXT_PUBLIC_IMAGES}/competitions/la-liga.png?width=${width ?? 25}`;
        case LEAGUES.COPA_DEL_REY:
            return `${process.env.NEXT_PUBLIC_IMAGES}/competitions/copa-del-rey.png?width=${width ?? 25}`;
        case LEAGUES.PREMIER_LEAGUE:
            return `${process.env.NEXT_PUBLIC_IMAGES}/competitions/premier-league.png?width=${width ?? 25}`;
        case LEAGUES.CHAMPIONS_LEAGUE:
            return `${process.env.NEXT_PUBLIC_IMAGES}/competitions/champion-league-logo.png?width=${
                width ?? 25
            }`;
        case LEAGUES.EUROPA_LEAGUE:
            return `${process.env.NEXT_PUBLIC_IMAGES}/competitions/europa-league.png?width=${width ?? 25}`;
        case LEAGUES.EUROPE_SUPER_CUP:
            return `${process.env.NEXT_PUBLIC_IMAGES}/competitions/super-cup-europe.png?width=${width ?? 25}`;
        case LEAGUES.SPAIN_SUPER_CUP:
            return `${process.env.NEXT_PUBLIC_IMAGES}/competitions/super-cup-spain.png?width=${width ?? 25}`;
        case LEAGUES.FIFA_WORLD_CLUB:
            return `${process.env.NEXT_PUBLIC_IMAGES}/competitions/fifa-world-club.png?width=${width ?? 25}`;
        case LEAGUES.FIFA_FRIENDLIES:
            return `${process.env.NEXT_PUBLIC_IMAGES}/competitions/fifa-friendlies.png?width=${width ?? 25}`;
        case LEAGUES.EURO_CLASIFICACION:
        case LEAGUES.EUROCOPA:
            return `${process.env.NEXT_PUBLIC_IMAGES}/competitions/eurocopa-logo.png?width=${width ?? 25}`;
        case LEAGUES.COPA_AMERICA:
            return `${process.env.NEXT_PUBLIC_IMAGES}/competitions/copa-america.png?width=${width ?? 25}`;
        default:
            return '';
    }
};

export const getLeagueTitle = (leagueId) => {
    switch (leagueId) {
        case LEAGUES.LA_LIGA:
            return 'La Liga';
        case LEAGUES.COPA_DEL_REY:
            return 'Copa del Rey';
        default:
            return '';
    }
};
