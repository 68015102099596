import Head from 'next/head';
import { Pages, RUTAS } from '@/constants/rutas';

const descriptions = {
    [Pages.INICIO]:
        'Compite en la quiniela fantasy y demuestra tus conocimientos usando el clásico formato de 1X2 mientras enfrentamos a los mejores jugadores en clave fantasy',
    [Pages.VOTACIONES]:
        'Participa y vota en la quiniela fantasy para demostrar tus conocimientos mientras enfrentamos a los mejores jugadores en clave fantasy en el formato 1x2',
    [Pages.INICIAR_SESION]:
        'Inicia sesión en la quiniela fantasy y compite contra otros managers para demostrar tus conocimientos mientras enfrentamos a los mejores jugadores en clave fantasy',
    [Pages.CREAR_CUENTA]:
        'Crea una cuenta en la quiniela fantasy y compite contra otros managers para demostrar tus conocimientos mientras enfrentamos a los mejores jugadores en clave fantasy',
    [Pages.QUINIELA_CLASIFICACION]:
        'Consulta la clasificación de la quiniela fantasy y compara tus resultados con otros managers mientras enfrentamos a los mejores jugadores en clave fantasy',
    [Pages.REGLAS_DEL_JUEGO]:
        'Conoce las reglas de la quiniela fantasy y compite contra otros managers para demostrar tus conocimientos mientras enfrentamos a los mejores jugadores en clave fantasy',
    [Pages.TERMINOS_CONDICIONES]:
        'Consulta los términos y condiciones de la quiniela fantasy y compite contra otros managers para demostrar tus conocimientos mientras enfrentamos a los mejores jugadores en clave fantasy',
    [Pages.POLITICAS_PRIVACIDAD]:
        'Consulta las políticas de privacidad de la quiniela fantasy y compite contra otros managers para demostrar tus conocimientos mientras enfrentamos a los mejores jugadores en clave fantasy',
    [Pages.CALENDARIO]:
        'Consulta el calendario de las competiciones de la quiniela fantasy y compite contra otros managers para demostrar tus conocimientos mientras enfrentamos a los mejores jugadores en clave fantasy',
    [Pages.VOTACIONES_MANAGERS]:
        'Consulta las votaciones de tus rivales para conocer sus estrategias en la quiniela fantasy y compite contra otros managers para demostrar tus conocimientos mientras enfrentamos a los mejores jugadores en clave fantasy',
    [Pages.PARTIDO]:
        'Consulta los partidos de la quiniela fantasy y compite contra otros managers para demostrar tus conocimientos mientras enfrentamos a los mejores jugadores en clave fantasy',
};

const titles = {
    [Pages.INICIO]: 'Quiniela Fantasy  | Quiniela Fantasy',
    [Pages.VOTACIONES]: 'Votaciones | Quiniela Fantasy',
    [Pages.INICIAR_SESION]: 'Iniciar sesión | Quiniela Fantasy',
    [Pages.CREAR_CUENTA]: 'Crear cuenta | Quiniela Fantasy',
    [Pages.QUINIELA_CLASIFICACION]: 'Clasificación de la quiniela | Quiniela Fantasy',
    [Pages.REGLAS_DEL_JUEGO]: 'Reglas de la quiniela | Quiniela Fantasy',
    [Pages.TERMINOS_CONDICIONES]: 'Terminos y condiciones | Quiniela Fantasy',
    [Pages.POLITICAS_PRIVACIDAD]: 'Políticas de privacidad | Quiniela Fantasy',
    [Pages.CALENDARIO]: 'Calendario | Quiniela Fantasy',
    [Pages.VOTACIONES_MANAGERS]: 'Votaciones de managers | Quiniela Fantasy',
    [Pages.PARTIDO]: 'Partido | Quiniela Fantasy',
};

const urls = {
    [Pages.INICIO]: RUTAS.INICIO,
    [Pages.VOTACIONES]: RUTAS.VOTACIONES,
    [Pages.INICIAR_SESION]: RUTAS.INICIAR_SESION,
    [Pages.CREAR_CUENTA]: RUTAS.CREAR_CUENTA,
    [Pages.QUINIELA_CLASIFICACION]: RUTAS.QUINIELA_CLASIFICACION,
    [Pages.REGLAS_DEL_JUEGO]: RUTAS.REGLAS_DEL_JUEGO,
    [Pages.TERMINOS_CONDICIONES]: RUTAS.TERMINOS_CONDICIONES,
    [Pages.POLITICAS_PRIVACIDAD]: RUTAS.POLITICAS_PRIVACIDAD,
    [Pages.CALENDARIO]: RUTAS.CALENDARIO,
    [Pages.VOTACIONES_MANAGERS]: RUTAS.VOTACIONES_MANAGERS,
    [Pages.PARTIDO]: RUTAS.PARTIDO,
};

const images = {
    [Pages.INICIO]: 'quiniela-fantasy.png',
    [Pages.VOTACIONES]: 'quiniela-fantasy.png',
    [Pages.INICIAR_SESION]: 'quiniela-fantasy.png',
    [Pages.CREAR_CUENTA]: 'quiniela-fantasy.png',
    [Pages.QUINIELA_CLASIFICACION]: 'quiniela-fantasy.png',
    [Pages.REGLAS_DEL_JUEGO]: 'quiniela-fantasy.png',
    [Pages.TERMINOS_CONDICIONES]: 'quiniela-fantasy.png',
    [Pages.POLITICAS_PRIVACIDAD]: 'quiniela-fantasy.png',
    [Pages.CALENDARIO]: 'quiniela-fantasy.png',
    [Pages.VOTACIONES_MANAGERS]: 'quiniela-fantasy.png',
    [Pages.PARTIDO]: 'quiniela-fantasy.png',
};

const SeoComponent = ({ pageId, description, title, url, image, summary }) => {
    url = url ?? `${process.env.NEXT_PUBLIC_URL_FRONT}${urls?.[pageId] ?? ''}`;
    title = title ?? titles?.[pageId] ?? '';
    description = description ?? descriptions?.[pageId] ?? '';
    image =
        image ?? `${process.env.NEXT_PUBLIC_IMAGES}/quiniela/${images?.[pageId] ?? 'quiniela-fantasy.png'}`;
    return (
        <Head>
            <title>{title}</title>
            <link rel="canonical" href={url} key="canonical" />
            <meta charSet="utf-8" />
            <meta name="theme-color" content="#ffffff" />
            <meta property="og:locale" content="es_ES" />
            <meta property="og:type" content="quiniela fantasy" key="type" />
            <meta property="og:site_name" content="QuinielaFantasy" />
            <meta name="description" content={description} key="name-description" />
            <meta property="og:url" content={url} key="url" />
            <meta property="og:title" content={title} key="title" />
            <meta property="og:description" content={description} key="description" />
            <meta property="og:image:secure_url" content={image} key="image" />

            <meta name="twitter:card" content={summary ?? 'summary_large_image'} key="twitter-card" />
            <meta name="twitter:image:alt" content="quiniela fantasy" key="twitter-card-image-alt" />
            <meta name="twitter:site" content="https://www.quinielafantasy.com" key="website" />
            <meta name="twitter:site:id" content="@AnaliticaLaLiga" key="website-twitter" />
            <meta name="twitter:creator" content="@AnaliticaLaLiga" key="twitter-creator" />
            <meta name="twitter:image" content={image} key="twitter-image" />
            <meta name="twitter:title" content={title} key="twitter-title" />
            <meta name="twitter:description" content={description} key="twitter-description" />

            <meta name="viewport" content="initial-scale=1.0, width=device-width" key="viewport" />
            <meta property="og:image:width" content="770" />
            <meta property="og:image:height" content="440" />
            <meta
                name="robots"
                content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
                key="robots"
            />
        </Head>
    );
};

export default SeoComponent;
