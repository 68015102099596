export const LEAGUES = {
    LA_LIGA: 140,
    PREMIER_LEAGUE: 39,
    SERIE_A: 135,
    PRIMEIRA_LIGA: 94,
    BUNDESLIGA: 78,
    LIGUE_1: 61,
    LIGA_SMARTBANK: 141,
    COPA_DEL_REY: 143,
    CHAMPIONS_LEAGUE: 2,
    EUROPA_LEAGUE: 3,
    EUROPE_SUPER_CUP: 531,
    SPAIN_SUPER_CUP: 556,
    FIFA_WORLD_CLUB: 15,
    NATIONS_LEAGUE: 5,
    UEFA_EUROPA_CONFERENCE_LEAGUE: 848,
    FIFA_FRIENDLIES: 10,
    EURO_CLASIFICACION: 960,
    EUROCOPA: 4,
    COPA_AMERICA: 9,
};
