import Link from 'next/link';
import CustomImageComponent from '../shared/custom/image';
import { getTeamImageById } from '@/data/teams';
import { esFechaMayorOIgualActual, getDateMMddYYYYHHmm } from '@/helpers/datetime-helper';
import { getCompetitionImage } from '@/helpers/leagues-helper';
import { translate } from '@/helpers/translations-helper';
import { RUTAS } from '@/constants/rutas';

const FixtureCard = ({
    date,
    fixtureId,
    leagueId,
    round,
    home,
    away,
    venue,
    referee,
    homeGoal,
    awayGoal,
    showLink = true,
}) => {
    const fecha = getDateMMddYYYYHHmm(date);

    const showFixtureLink = () => {
        const isDateBiggerThanNow = esFechaMayorOIgualActual(date);

        if (showLink && isDateBiggerThanNow) {
            return (
                <Link href={`${RUTAS.PARTIDO}/${fixtureId}`}>
                    <div className="fixture-card__partido">Ver estadísticas</div>
                </Link>
            );
        }
    };

    return (
        <div className="fixture-card">
            <div className="fixture-card__competition">
                <p className="mb-0">
                    <CustomImageComponent
                        src={getCompetitionImage(leagueId, 40)}
                        width={20}
                        height={20}
                        alt={'competition'}
                    />
                    <span className="fixture-card__round">{translate(round)}</span>
                </p>
                <p className="mb-0">{fecha}</p>
            </div>

            <div className="fixture-card__teams">
                <div className="fixture-card__team">
                    <CustomImageComponent
                        src={getTeamImageById(home?.id, 60)}
                        width={40}
                        height={40}
                        alt={'equipo'}
                    />
                    <p className="mb-0">{home?.name}</p>
                </div>
                <div className="fixture-card__score">
                    <div className="fixture-card__box">
                        <div className="fixture-card__goals">{homeGoal ?? '-'}</div>
                        <div className="fixture-card__goals">{awayGoal ?? '-'}</div>
                    </div>
                    {showFixtureLink()}
                </div>
                <div className="fixture-card__team">
                    <CustomImageComponent
                        src={getTeamImageById(away?.id, 60)}
                        width={40}
                        height={40}
                        alt={'equipo'}
                    />
                    <p className="mb-0">{away?.name}</p>
                </div>
            </div>
            <div className="fixture-card__info">
                <p className="fixture-card__info-line">Estadio: {venue?.name ?? '-'}</p>
                <p className="fixture-card__info-line">Ciudad: {venue?.city ?? '-'}</p>
                <p className="fixture-card__info-line">Árbitro: {referee ?? '-'}</p>
            </div>
        </div>
    );
};

export default FixtureCard;
